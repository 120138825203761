'use client';
import React from 'react';
import Script from 'next/script';
import { Identity } from '@/hooks/useIdentity';
import { Config } from '@/hooks/useConfig';

import GlobalStyle from '@/styles/globalStyles';
import PostHogProvider from './PostHogProvider';
import { IUser } from '@/types';
import { IConfigResponse } from '@/lib/configApi';
import { GOOGLE_ANALYTICS_ID } from '@/config/environment';

const GoogleAnalytics: React.FC<{ gaId: string }> = ({ gaId }) => {
  return (
    <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gaId}', {
          page_path: window.location.pathname,
          });
          `,
        }}
      />
    </>
  );
};

export const Providers: React.FC<{ user?: IUser; configResponse?: IConfigResponse; children: React.ReactNode }> = ({
  user,
  configResponse,
  children,
}) => {
  return (
    <>
      <GoogleAnalytics gaId={GOOGLE_ANALYTICS_ID} />
      <PostHogProvider user={user as IUser}>
        <Config.Provider initialState={configResponse}>
          <Identity.Provider initialState={user as IUser}>
            <GlobalStyle />
            {children}
          </Identity.Provider>
        </Config.Provider>
      </PostHogProvider>
    </>
  );
};

export const MaintenanceProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <GoogleAnalytics gaId={GOOGLE_ANALYTICS_ID} />
      <GlobalStyle />
      {children}
    </>
  );
};
